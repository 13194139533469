import { PageTitle } from "../../../_metronic/layout/core"
import {FC, useContext, useEffect, useState} from 'react'
import { toAbsoluteUrl } from "../../../_metronic/helpers"
import { sendRequest } from "../../../Helpers/Requests"
import ConfigContext from "../../contexts/Config/ConfigContext"
import Countdown from "react-countdown"
import {
    CardsWidget20,
    CardsWidget17
  } from '../../../_metronic/partials/widgets'
import {IndicationLink} from '../../../_metronic/partials/widgets/indicationLink'
import './ClickToEarn.css'

const DashboardClickToEarn: FC = () => {
    const config = useContext(ConfigContext)

    const [valorGanho, setValorGanho] = useState<any>(0)
    const [valorReceber, setValorReceber] = useState<any>(0)
    const [tetoPlano, setTetoPlano] = useState<any>(0)
    const [prctGanhos, setPrctGanhos] = useState<any>(0)
    const [valorProgresso, setValorProgresso] = useState<any>(0)

    const [totalGanhos, setTotalGanhos] = useState<any>(0)
    const [totalBinario, setTotalBinario] = useState<any>(0)
    const [totalDiarios, setTotalDiario] = useState<any>(0)
    const [totalIndicacao, setTotalIndicacao] = useState<any>(0)
    const [totalUnilevel, setTotalUnilevel] = useState<any>(0)
    const [totalResidual, setTotalResidual] = useState<any>(0)

    const [assinatura, setAssinatura] = useState(null)
    const [subscription, setSubscription] = useState({})
    const [loadedCards, setLoadedCards] = useState<boolean>(false)

    const [clicks, setClicks] = useState(0)
    const [lastClick, setLastClick] = useState(0)

    const [releaseClick, setReleaseClick] = useState(true)

    const loadClicks = async () => {
        const req = await sendRequest('click_to_earn/loadMyClick', 'GET')
        const response = await req.click
        setClicks(response.clicks_remaining)
        // alert(response.lastClick)
        setLastClick(response.lastClick)
        getGanhos()
    }

    const handleClickToEarn = async () => {

        if (releaseClick) {
          setReleaseClick(false)
          const req = await sendRequest('click_to_earn/click', 'GET')
          loadClicks()
          setTimeout(() => setReleaseClick(true), 2000)
        }
    }

    const getAssinatura = async () => {
        const req = sendRequest(`usuario/assinatura`, 'GET')
    
        const response = await req
    
        if (response.tipo === 'success') {
          const {
            plan,
            valor_progresso,
            val_receber,
            val_ganho,
            teto_plano,
            prct_ganho,
            binarioQualificado,
          } = response
    
          setSubscription(response)
          setAssinatura(plan)
          setValorGanho(val_ganho)
          setValorReceber(val_receber)
          setTetoPlano(teto_plano)
          setPrctGanhos(prct_ganho)
          setValorProgresso(valor_progresso)
          setLoadedCards(true)
        }
      }

      const getGanhos = async () => {
        const req = await sendRequest(`usuario/ganhos`, 'GET')
    
        const response = await req
    
        if (response.tipo === 'success') {
          const {
            binario_total,
            diario_total,
            entradas_total,
            indicacao_total,
            residual_total,
            unilevel_total,
          } = response
    
          setTotalGanhos(entradas_total)
          setTotalBinario(binario_total)
          setTotalDiario(diario_total)
          setTotalIndicacao(indicacao_total)
          setTotalUnilevel(unilevel_total)
          setTotalResidual(residual_total)
        }
      }

    useEffect(() => {
        getAssinatura()
        loadClicks()
        getGanhos()
    }, [])

    return (
      <>
        <PageTitle breadcrumbs={[]}>Click To Earn</PageTitle>

        <div className="row g-5 g-xl-10 mb-1 mb-xl-1" style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <div className="col-6">
                <CardsWidget20
                    className="h-md-80 mb-5 mb-xl-10"
                    description='Retorno do investimento'
                    color='orange'
                    img={toAbsoluteUrl('/media/patterns/vector-1.png')}
                    valorGanho={valorGanho}
                    valorReceber={valorReceber}
                    tetoPlano={tetoPlano}
                    prctGanhos={prctGanhos}
                    progresso={valorProgresso}
                    config={config}
                    assinatura={assinatura}
                    subscription={subscription}
                    loaded={loadedCards}
                />
            </div>

            <div className="col-6">
            <CardsWidget17
                className="h-md-80 mb-5 mb-xl-10"
                total={totalGanhos}
                binario={totalBinario}
                diario={totalDiarios}
                indicacao={totalIndicacao}
                unilevel={totalUnilevel}
                residual={totalResidual}
                config={config}
                loaded={loadedCards}
            />
            </div>
        </div>
        
        <div className="text-center">
            <h2 style={{textAlign:'center'}}>Clique para Ganhar</h2>
        </div>

        <div className="text-center mt-2 mb-2">
            <h2 style={{textAlign:'center'}}>Mais de 5000 pessoas já se inscreveram! Junte-se a elas e não perca essa oportunidade.</h2>
        </div>

        <div style={{display:'flex', alignItems:'center', justifyContent:'space-around'}}>
            <img className="coinButton" onClick={() => handleClickToEarn()} src="https://painel.airdrophammercoin.tech/storage/site/logo.png" style={{width:400}}/>
        </div>

        <div className="text-center mt-15">
            <div style={{display:'flex', alignItems:'center', justifyContent:'space-around'}}>
                <h2>{clicks}/{config.clicks_to_earn}</h2>
                <div>
                  <h2>HMC 500.000</h2>
                  <h2>{config.value_to_earn}</h2>
                </div>
            </div>
        </div>

        {clicks == 0 &&
            <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop: 25}}>
                <h2 style={{backgroundColor: 'orange', padding:20, borderRadius: 20}}>
                    Seus clicks renovam em: &nbsp;
                    <Countdown 
                        intervalDelay={0} 
                        precision={3}
                        autoStart={true}
                        date={new Date(lastClick)}
                        onComplete={() => loadClicks()}
                      />
                </h2>
            </div>
        }

      {/* <div className='row g-5 mt-15 mb-xl-1'>
        <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12  '>
          <IndicationLink className='card-xxl-stretch mb-5 mb-xxl-12' config={config} />
        </div>
      </div> */}
      </>
    )
  }
export {DashboardClickToEarn}